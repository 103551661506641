import(/* webpackMode: "eager" */ "/app/components/Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/login/Modal.jsx");
;
import(/* webpackMode: "eager" */ "/app/components/Navbar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Recaptcha.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/register/Modal.jsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
