'use client'

import React from 'react'
import { useSelector } from "react-redux";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import useScrollTracking from "@/core/helpers/useScrollTracking";

import { getIsErrorServer, getIsTimeout } from "@/core/store/reducer/apiSlice";

import ErrorServer from "@/app/500";
import Timeout from "@/app/timeout";

export default function Recaptcha({ children }) {
    useScrollTracking();
    
    const isTimeout = useSelector(getIsTimeout);
    const isErrorServer = useSelector(getIsErrorServer);

    if (isTimeout) return <Timeout />
    if (isErrorServer) return <ErrorServer />

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={
                process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY
            }
            scriptProps={{
                async: false,
                defer: false,
                appendTo: "head",
                nonce: undefined,
            }}
            useEnterprise={true}
        >
            {children}
        </GoogleReCaptchaProvider>
    )
}
